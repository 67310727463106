import * as React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Helmet } from "react-helmet"

const ThankYou = () => (
  <Layout>
    <SEO title="Thank you" />
    <Helmet>
      <meta name="robots" content="noindex,nofollow" />
    </Helmet>
    <section className="container">
      <h1 className="section-heading">Thank you</h1>
      <div class="alert alert-success mt-4 text-center">
        Your message has been sent to our email. We will be in touch soon.
      </div>
    </section>
  </Layout>
)

export default ThankYou
